<template></template>

<script>
import axios from 'axios';
import Vue from 'vue';

export default {
  name: "SelectInstance",
  mounted: function () {
    this.Instance();
  },
  methods: {
    Instance: function () {
      let self = this;
      let partner_id = this.$route.query.partnerid;
      localStorage.setItem("partner_id", partner_id);
      axios
        .get(`${this.$apiAdress}/v1/Language/GetByCode/en-US`)
        .then(function (response) {
          Vue.prototype.$dateFormat = response.data.datetimeFormat.replace("dd", "DD").replace("tt", "A");
          Vue.prototype.$dateFormatInput = response.data.datetimeFormat.replace("tt", "a");
        });
      if(localStorage.getItem("experienceCategoryFilter")){
        localStorage.removeItem("experienceCategoryFilter");
      }
      let data = JSON.parse(localStorage.getItem("user"));
      for (let k = 0; k < data.partnerPermissions.length; k++) {
        if (data.partnerPermissions[k]["partnerId"] == partner_id) {
          localStorage.setItem(
            "partner_name",
            data.partnerPermissions[k]["partnerName"]
          );
          break;
        }
      }

      this.$store.commit("instance", localStorage.getItem("partner_name"));
      
      const previousPartnerId = this.$store.state.partnerId;
      // if previous partner id is different from current partner id, then empty widget's cart
      if (previousPartnerId && previousPartnerId !== partner_id) {
        const widgetEmptyCartEvent = new CustomEvent('stayify-empty-cart');
        document.dispatchEvent(widgetEmptyCartEvent);
      }

      this.$store.commit("partnerId", partner_id);

      // navigate to last top level path
      const lastTopLevelPath = this.$store.state.activeTopLevelPath;
      if (lastTopLevelPath !== '/instance') {
        self.$router.push(lastTopLevelPath);
      } else {
        self.$router.push('/dashboard');
      }
    },
  },
};
</script>
